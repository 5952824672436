import React from "react"
import { useCaseStudyPhotos } from "./useCaseStudyPhotos"

export const useCaseStudyData = () => {
  const photos = useCaseStudyPhotos()
  return [
    {
      name: "Cersanit/Opoczno projekt shop-in-shop",
      number: "01",
      link: "/case-study/cersanit",
      description: (
        <>
          Opracowanie aranżacji stref sprzedażowych w&nbsp;punktach handlowych,
          produkcja stojaków...
        </>
      ),
      image: photos.cersanit.childImageSharp.fluid,
      btnText: 'Zobacz więcej',
    },
    {
      name: "Futuro i Viscoplast - mini kampanie",
      number: "02",
      link: "/case-study/futuro-viscoplast",
      description: (
        <>
          Odświeżenie materiałów sprzedażowych, opracowanie nowych stojaków do
          aptek...
        </>
      ),
      image: photos.futuro.childImageSharp.fluid,
      btnText: 'Zobacz więcej',
    },
    {
      name: "Shell deli2go - mała gastronomia na stacjach paliw",
      number: "03",
      link: "/case-study/shell-deli2go",
      description: (
        <>
          Projekt dotyczył wykonania zabudów gastronomicznych dla ponad 200
          stacji Shell. Prace obejmowały...
        </>
      ),
      image: photos.shell.childImageSharp.fluid,
      btnText: 'Zobacz więcej',
    },
    {
      name: "VOX - Modułowy system ekspozycyjny",
      number: "04",
      link: "/case-study/vox",
      description: (
        <>
          Przygotowanie modularnego systemu ekspozycyjnego na panele podłogowe
          i&nbsp;akcesoria...
        </>
      ),
      image: photos.vox.childImageSharp.fluid,
      btnText: 'Zobacz więcej',
    },
  ]
}
