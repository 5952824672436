import {useCaseStudyData} from '../shared/Hooks/CaseStudy';
import Image from 'gatsby-image';
import React from 'react';
import {useBackgroundImage} from '../shared/Hooks/Background';

export const useData = () => {
  const cases = useCaseStudyData();
  const backgroundImgs = useBackgroundImage();

  return {
    seo: {
      title: 'Case study',
    },
    parallaxText: (
      <Image fixed={backgroundImgs.caseStudy.childImageSharp.fixed}/>
    ),
    title: 'Case study',
    cases,
  };
};
